//Vendors
import 'jquery-ui';
import 'jquery-ui/themes/base/all.css';
import '@iconscout/unicons/css/solid.css';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import 'bootstrap'
import 'select2'
import 'select2/dist/css/select2.css';

import './scss/main.scss';

import './js/modules/box';
import './main';


