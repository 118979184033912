import './vendor/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';
import './vendor/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';

let reusableTinymce = require('./js/modules/tinymce');

$(document).ready(function () {
    $('.datetimepicker').datetimepicker({
        //    locale: 'pl',
        format: 'YYYY-MM-DD HH:mm:ss',
        //minDate: new Date(),
    });
    reusableTinymce.initTinyMce();
    $('input[type="file"][id*="_video"]').on('change', function (e) {
        $('#js__added-film-name').remove();
        $(this).after('<p id="js__added-film-name">Dodano film: ' + e.target.files[0].name + '</p>');
    });

    var saveOverlay = $('' +
        '<div id="overlay" class="overlay" style="display:flex;height:100vh;align-items: center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);filter:alpha(opacity=50);-moz-opacity:0.5;-khtml-opacity:0.5;opacity 0.5;z-index:10000;">' +
        '<div style="text-align:center;background-color:#fff;padding:15px">' +
        '<h1 style="width:100%;">Trwa zapisywanie prosimy o cierpliwość</h1>' +
        '</div>' +
        '</div>');

    var sendOverlay = $('' +
        '<div id="overlay" class="overlay" style="display:flex;height:100vh;align-items: center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);filter:alpha(opacity=50);-moz-opacity:0.5;-khtml-opacity:0.5;opacity 0.5;z-index:10000;">' +
        '<div style="text-align:center;background-color:#fff;padding:15px">' +
        '<h1 style="width:100%;">Trwa wysyłanie prosimy o cierpliwość</h1>' +
        '</div>' +
        '</div>');


    $('button[name="btn_send"]').on('click', function () {
        sendOverlay.appendTo(document.body);
    });

    $('button[name="btn_create_and_edit"]').on('click', function () {
        var action = $('button[name="btn_create_and_edit"]').closest('form').prop('action');
        var reg = new RegExp('\/admin\/app\/message\/create');
        if (reg.test(action)) {
            saveOverlay.appendTo(document.body);
        } else {
            saveOverlay.appendTo(document.body);
        }
    });

    $('button[name="btn_create_and_create"]').on('click', function () {
        saveOverlay.appendTo(document.body);
    });

    $('button[name="btn_create_and_list"]').on('click', function () {
        saveOverlay.appendTo(document.body);
    });

    $('button[name^="btn_update_"]').on('click', function () {
        saveOverlay.appendTo(document.body);
    });

    admin.formValidation();

    $('.js__accept_order').on('click', function (e) {
        e.preventDefault();
        var button = $(this);
        button.hide();
        saveOverlay.appendTo(document.body);

        $.post(Routing.generate('ajax_accept_client_order', {id: $(this).data('order')}), {})
            .done(function (response) {
                if (response.message) {
                    button.hide();
                    location.reload();
                } else {
                    button.show();
                }
            });
    });

    var select = $('select[id$="_clients"]');

    if (select.length) {
        select = select[0];
        $('#' + select.id).on("change", function (e) {
            $checkAll = $('input[id$=_postToAll]');
            if (e && e.removed && $checkAll.length && $checkAll.is(":checked")) {
                $checkAll.attr('checked', false);
            }
        });
    }

    if (typeof $('select[id$="_clients"]').attr('readonly') != 'undefined') {
        $('select[id$="_clients"]').select2("readonly", true);
    }
    if (typeof $('select[id$="_product"]').attr('readonly') != 'undefined') {
        $('select[id$="_product"]').select2("readonly", true);
    }
    if (typeof $('select[id$="_group"]').attr('readonly') != 'undefined') {
        $('select[id$="_group"]').select2("readonly", true);
    }
    if (typeof $('select[id$="_sales"]').attr('readonly') != 'undefined') {
        $('select[id$="_sales"]').select2("readonly", true);
    }
    if (typeof $('select[id$="_managers"]').attr('readonly') != 'undefined') {
        $('select[id$="_managers"]').select2("readonly", true);
    }
});

var checkMimeType = function (type) {

    type = type.toLowerCase();

    switch (type) {
        case 'flv':
            return true;
        case 'ogg':
            return true;
        case 'mp4':
            return true;
        case 'm3u8':
            return true;
        case 'ts':
            return true;
        case 'gp':
            return true;
        case 'mov':
            return true;
        case 'avi':
            return true;
        case 'wmv':
            return true;
        default:
            return false;
    }
};

var validation = function (e) {

    tinymce.triggerSave();

    var errors = 0;
    $('.form-group').removeClass('has-error');
    $('.sonata-ba-field-error-messages').remove();
    $(document).find('.form-control, select').each(function () {
        if ($(this).prop('required')) {
            if (!$(this).val()) {
                $(this).closest('.form-group').addClass('has-error');
                $(this).after(' <div class="help-block sonata-ba-field-error-messages"><ul class="list-unstyled"><li><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Ta wartość nie powinna być pusta.</li></ul></div>');
                errors++;

                if (errors == 1) {
                    $('html, body').animate({scrollTop: $(this).offset().top - 150}, 350);
                }
            }
        }
    });
    $(document).find('.form-control[type="number"]').each(function () {
        var testNumber = (/^\d+$/);
        if (!testNumber.test($(this).val())) {
            $(this).closest('.form-group').addClass('has-error');
            $(this).after(' <div class="help-block sonata-ba-field-error-messages"><ul class="list-unstyled"><li><i class="fa fa-exclamation-circle" aria-hidden="true"></i>Ta wartość powinna być liczbą</li></ul></div>');
            errors++;

            if (errors == 1) {
                $('html, body').animate({scrollTop: $(this).offset().top - 150}, 350);
            }
        }

        var minLimit = 0;
        if ($(this).val() < minLimit) {
            $(this).closest('.form-group').addClass('has-error');
            $(this).after(' <div class="help-block sonata-ba-field-error-messages"><ul class="list-unstyled"><li><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Ta wartość nie powinna być większa bądź równa  ' + minLimit + '</li></ul></div>');
            errors++;

            if (errors == 1) {
                $('html, body').animate({scrollTop: $(this).offset().top - 150}, 350);
            }
        }

        var maxLimit = 99999;
        if (maxLimit < $(this).val()) {
            $(this).closest('.form-group').addClass('has-error');
            $(this).after(' <div class="help-block sonata-ba-field-error-messages"><ul class="list-unstyled"><li><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Ta wartość nie powinna być mniejsza bądź równa ' + maxLimit + '</li></ul></div>');
            errors++;

            if (errors == 1) {
                $('html, body').animate({scrollTop: $(this).offset().top - 150}, 350);
            }
        }
    });

    $(document).find('input[id$="_video"]').each(function () {

        var fileName = $(this).val();
        var regex = /[a-zA-Z0-4]+$/;

        if (fileName && !checkMimeType(fileName.match(regex)[0])) {
            $(this).closest('.form-group').addClass('has-error');
            $(this).after(' <div class="help-block sonata-ba-field-error-messages">' +
                '<ul class="list-unstyled"><li><i class="fa fa-exclamation-circle" aria-hidden="true"></i> ' +
                'Dopuszczalny typ pliku: video.</li></ul>' +
                '</div>');
            errors++;

            if (errors == 1) {
                $('html, body').animate({scrollTop: $(this).offset().top - 150}, 350);
            }
        }
    });

    if (errors > 0) {
        e.preventDefault();
        $('#overlay').remove();
    }
};

var admin = {
    formValidation: function () {
        if ($('.form-actions').length) {
            $('button[name="btn_update_and_edit"]').on('click', validation);
            $('button[name="btn_preview"]').on('click', validation);
            $('button[name="btn_create_and_edit"]').on('click', validation);
            $('button[name="btn_create_and_create"]').on('click', validation);
        }
    }
};

window.addEventListener('load', function () {
    if ($('.child_menu .active').length) {
        $('.child_menu .active').closest('.child_menu').css('display', 'block');
        $('.child_menu .active').addClass('current-page');
    }
});

$(function () {
    $('.active.treeview').addClass('current-page');
    initAutocompleteFilter();
})

$(document).on('click', '.reset-select2', function (e) {
    e.preventDefault();
    $(this).parent().find('input[type="text"]').select2('val', '');
})

$('.dropdown-toggle').on('click', function () {
    $(this).closest('.dropdown').find('.dropdown-menu').toggleClass('show');
    $(this).closest('.btn-group').find('.dropdown-menu').toggleClass('show');
})

let initAutocompleteFilter = function () {
    $(document).on('change', '[data-autocomplete-filter]', function () {
        if ($(this).val()) {
            $('[data-autocomplete-filter]').not($(this)).attr('disabled', 'disabled')
            $('[id$="client_autocomplete_input"]').val(null)
        } else {
            $('[data-autocomplete-filter]').removeAttr('disabled')
        }

        $('.autocomplete-filter-target').attr('data-' + $(this).data('autocompleteFilter'), $(this).val())
        $('.autocomplete-filter-target').select2('val', '');
    })

    $(document).on('select2-open', 'input[id$="_autocomplete_input"]', function () {
        $('input[id$="_autocomplete_input"]').not($($(this).context)).select2('close');
    })
}

$('a[href="#"]').on('click', function (e) {
    e.preventDefault();
});

