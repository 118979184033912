import 'select2';

+function ($) {
    "use strict";
    jQuery.fn.box = function (configOptions) {

        let $box, $activeBtn, $body, $dialog, $footer, $progress;

        let options = {
            'target': '#contentBox',
            'size': 'md',
            'show': true,
            'title': 'Add',
            'closeDelay': 0,
            'appendTarget': false,
            'reload': false
        };
        options = jQuery.extend(options, configOptions);

        $(document).on('click', '.content-box', function (e) {
            e.preventDefault();

            $box = $(generate());
            $activeBtn = $(this);
            options = jQuery.extend(options, $activeBtn.data());

            $body = $box.find('.modal-body');

            $dialog = $box.find('.modal-dialog');
            let $content = $box.find('.modal-content');
            $footer = $box.find('.modal-footer');
            $progress = $box.find('.progress').hide();

            $body.empty();
            $activeBtn.addClass('active');

            before();
            let title = $activeBtn.attr('title');
            if (title && title.length > 0) {
                setTitle($activeBtn.attr('title'));
            } else {
                setTitle($activeBtn.html());
            }
            $content.show();
            $box.modal('show');

            $.ajax({
                type: 'GET',
                url: $activeBtn.attr('href'),
                cache: true,
                success: function (data) {
                    generateContent(data, $body, $progress, $footer, $box, options, $activeBtn);
                },
                complete: function () {
                    $content.hide();
                    $activeBtn.removeClass('active');
                },
                error: function (code) {
                    let status = code.status;
                    if (status === 403)
                        $body.append($('<div>').addClass('alert alert-danger').html('Access denied'));
                    else
                        $body.append($('<div>').addClass('alert alert-warning').html('System error'))
                }
            });

        });
        $(document).on('show.bs.modal', '.modal', function () {
            let zIndex = 1040 + (10 * $('.modal:visible').length);
            $(this).css('z-index', zIndex);
            setTimeout(function () {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });

        let setTitle = function (title) {
            $box.find('.modal-title').first().html(title);
        };
        let showAlert = function (type) {
            $body.prepend('<div class="alert alert-' + type.alertType + '">' + type.alertContent + '</div>').hide().fadeIn();
        };
        let showCustomAlert = function (type, text) {
            $body.prepend('<div class="alert alert-' + type + '">' + text + '</div>').hide().fadeIn();
        };
        let generateContent = function (data, $body, $progress, $footer, $box, options, $activeBtn) {
            $footer.find('.btn').not('.btn-default').remove();
            $body.hide();
            $body.empty();
            let $data = $(data);
            let content = options.content;
            $body.append($data.find(content));

            initSelect2($box);

            $body.fadeIn();
            $body.find(content).css('margin', 0).css('padding', 0);
            let $form_submit = $body.find('button');
            let $submit = $form_submit.clone();
            $form_submit.hide();
            $body.find('.form-actions').hide();
            $submit.on('click', function () {
                $form_submit.trigger('click');
            });
            $footer.prepend($submit);

            setTimeout(function () {
                $body.scrollTop(0);
            }, 1000);
            $('.box-goto').attr('href', $activeBtn.attr('href'))
            $box.find("form").on('submit', function (e) {
                e.preventDefault();
                let $this = $(this);
                let formAction = $this.attr('action');
                let formMethod = $this.attr('method');

                $.ajax({
                    url: $activeBtn.attr('href'),
                    method: formMethod,
                    data: $this.serialize(),
                    headers: {
                        'Box-Request': 'true',
                    },
                    success: function (data) {
                        if (data.value) {
                            if (options.appendTarget) {
                                let $select = $(options.appendTarget);
                                let object = {
                                    id: data.id,
                                    value: data.id,
                                    text: data.value,
                                    selected: true
                                };
                                $select.prepend($('<option>', object));
                                if (typeof $select.attr('multiple') != 'undefined') {
                                    $select.val($select.val().concat(data.id)).trigger("change").trigger({
                                        type: 'select2:select',
                                        params: {
                                            data: object
                                        }
                                    });
                                } else {
                                    $select.val(data.id).trigger("change").trigger({
                                        type: 'select2:select',
                                        params: {
                                            data: object
                                        }
                                    });
                                }
                            }
                            setTimeout(function () {
                                    $box.modal('hide');
                                    if (options.reload) {
                                        location.reload();
                                    }
                                }, options.closeDelay
                            );
                        } else {
                            generateContent(data, $body, $progress, $footer, $box, options, $activeBtn)
                        }

                    },
                    error: function (data) {
                        let json = data.responseJSON;
                        if (json.message && json.type) {
                            showCustomAlert(json.type, json.message)
                        } else {
                            showAlert(options.type.cancel);
                        }
                    }
                });
            });
            $box.find("button").on('submit', function (e) {
                e.preventDefault();
            });
        };

        let before = function () {
            $body.find('.alert').remove();
            if (options.size) {
                $dialog.removeClass('modal-lg');
                $dialog.addClass('modal-' + options.size)
            }
        }
    };
    let generate = function () {
        let name = 'content-box' + Math.floor(Math.random() * 100000 * 999000);
        let $closeButton = $('<button></button>').attr('data-dismiss', 'modal').addClass('btn btn-default btn-outline-primary pull-right').html(Translator.trans('close', {}, 'app_base'));
        let $goToButton = $('<a></a>').addClass('btn btn-default btn-info atmatic-goto').html(Translator.trans('go_to', {}, 'app_base'));
        let $boxFooter = $('<div></div>').addClass('modal-footer').append($goToButton).append($closeButton);
        let $boxBody = $('<div></div>').addClass('modal-body');
        let $boxHeader = $('<div></div>').addClass('modal-header').html('<h4 class="modal-title"></h4><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
        let $boxContent = $('<div></div>').addClass('modal-content').append([$boxHeader, $boxBody, $boxFooter]);
        let $boxDialog = $('<div></div>').addClass('modal-dialog modal-lg').append($boxContent);
        let $box = $('<div></div>').addClass('modal fade').attr('id', name).append($boxDialog);
        $('body').append($box);
        return '#' + name;
    };
    let initSelect2 = function () {
        $('select.select2').select2({
            'theme': 'bootstrap'
        });
    }
}(window.jQuery);
