initTinyMce = function () {
    console.warn('INIT Tinymce')
    tinymce.init({
        language: 'pl',
        language_url: '/js/languages/pl.js',
        selector: '.tinymce',
        //toolbar1: "undo redo italic alignleft aligncenter alignright alignjustify bullist numlist responsivefilemanager code",
        toolbar1: "undo redo | styleselect fontselect fontsizeselect  |  forecolor backcolor colorpicker | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | link responsivefilemanager table | code | removeformat | subscript superscript | charmap | hr | blockquote ",
        menubar: '',
        upload: {
            maxsize: 50
        },
        setup: function (ed) {
            ed.on('NodeChange', function (e) {
                $('textarea.textarea').val(ed.getContent());
            });
            ed.on('keyup', function(e) {
                $('#'+ed.id).val(ed.getContent());
            });
            ed.on('change', function(e) {
                $('#'+ed.id).val(ed.getContent());
                var contentVal = ed.getContent();
                $('textarea.textarea').val(contentVal);
            });
        },
        plugins: [
            "advlist autolink lists link image charmap print preview anchor textcolor colorpicker",
            "searchreplace visualblocks code fullscreen", "table contextmenu paste ", "autoresize", "responsivefilemanager"
        ],
        image_advtab: true,
        relative_urls: false,
        external_filemanager_path: "/filemanager/",
        filemanager_title: "Filemanager",
        external_plugins: {
            "filemanager": "../../../../../filemanager/plugin.min.js",
            "forecolor": "../../../../../tinymce_plugins/textcolor/plugin.min.js",
        }
    });

    tinymce.PluginManager.load("responsivefilemanager", "../../../../../../../filemanager/plugin/responsivefilemanager/plugin.js");
    tinymce.PluginManager.load("forecolor", "../../../../../../../tinymce_plugins/textcolor/plugin.js");
}


let destroyTinyMce = function (){
    tinymce.remove('.tinymce');
}

module.exports = {
    initTinyMce: initTinyMce,
    destroyTinyMce: destroyTinyMce
};
